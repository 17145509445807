<template>
	<div>
		<a-table :rowKey="(e) => e.id" class="white-table" :columns="columns" :data-source="data">
			<template slot="action" slot-scope="obj">
				<div class="dF" style="color:var(--orange)">
					<div class="dF aC" style="cursor: pointer;" @click="$emit('downloadFile', obj)">
						<a-icon type="download" class="mr-3" style="font-size:20px" />
						<div style="line-height: 20px;">CSV</div>
					</div>
				</div>
			</template>
		</a-table>
		<a-alert message="Unsubscribes will not be included on exported file." type="info"
			show-icon style="width: fit-content;">
		</a-alert>
	</div>
</template>

<script>
export default {
	props: {
		data: {
			default: () => ([]),
			type: Array
		}
	},
	data() {
		return {
			columns: [
				{
					title: 'Name',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: 'Action',
					key: 'action',
					scopedSlots: { customRender: 'action' },
				},
			],
		}
	}
}
</script>

<style></style>
