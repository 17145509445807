<template>
    <div>
		<div class="dF aC" style="gap: 0.5em 2em; flex-wrap: wrap;">
            <div>
                <a-checkbox value="1" :checked="previewAccount.template == '1'" @change="onChange" class="templateCheckbox">
                    <div style="width: 270px; height:150px">
                        <img class="templateImage" :style="previewAccount.template== '1' ? 'border:1px solid var(--orange)' : 'border:1px solid #ECEBED' " style="width: 100%; object-fit: contain; border-radius:4px" :src="require('@/assets/template-1.svg')" />
                    </div>
                </a-checkbox>
            </div>
            <div>
                <a-checkbox value="2" :checked="previewAccount.template == '2'" @change="onChange" class="templateCheckbox">
                    <div style="width: 270px; height:150px">
                        <img class="templateImage" :style="previewAccount.template== '2' ? 'border:1px solid var(--orange)' : 'border:1px solid #ECEBED' " style="width: 100%; height:100%; object-fit: contain; border-radius:4px" :src="require('@/assets/template-2.svg')" />
                    </div>
                </a-checkbox>
            </div>
            <div>
                <a-checkbox value="3" :checked="previewAccount.template == '3'" @change="onChange" class="templateCheckbox">
                    <div style="width: 270px; height:150px">
                        <img class="templateImage" :style="previewAccount.template== '3' ? 'border:1px solid var(--orange)' : 'border:1px solid #ECEBED' " style="width: 100%; height:100%; object-fit: contain; border-radius:4px" :src="require('@/assets/template-3.svg')" />
                    </div>
                </a-checkbox>
            </div>
            <div>
                <a-checkbox value="4" :checked="previewAccount.template == '4'" @change="onChange" class="templateCheckbox">
                    <div style="width: 270px; height:150px">
                        <img class="templateImage" :style="previewAccount.template== '4' ? 'border:1px solid var(--orange)' : 'border:1px solid #ECEBED' " style="width: 100%; height:100%; object-fit: contain; border-radius:4px" :src="require('@/assets/template-4.svg')" />
                    </div>
                </a-checkbox>
            </div>
			<div>
                <a-checkbox value="5" :checked="previewAccount.template == '5'" @change="onChange" class="templateCheckbox">
                    <div style="width: 270px; height:150px">
                        <img class="templateImage" :style="previewAccount.template== '5' ? 'border:1px solid var(--orange)' : 'border:1px solid #ECEBED' " style="width: 100%; height:100%; object-fit: contain; border-radius:4px" :src="require('@/assets/template-5.svg')" />
                    </div>
                </a-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    watch:{

    },
    data(){
        return{
            templateType:'1',
            checked:false,
            loading:false,
        }
    },
    computed:{
        previewAccount(){
            return this.$store.state.contacts.previewAccount
        },
        settings(){
            return this.$store.state.contacts.allSettings.userApp
        },
        account(){
            return this.$store.state.contacts.allSettings.userApp.options.account
        }
    },
    watch:{
        previewAccount:{
            handler(val) {

            },
            deep:true
        }
    },
    methods:{
        cancel(){
            let account = JSON.parse(JSON.stringify(this.account))
            this.$store.commit('PREVIEW_ACCOUNT', account)
        },
        onChange(e){
            this.templateType = e.target.value
            let sendObj = JSON.parse(JSON.stringify(this.previewAccount))
            sendObj.template = this.templateType
            this.$store.commit('PREVIEW_ACCOUNT',sendObj)
        },
        saveTemplate() {
            console.log('previewAccount', this.previewAccount)
            let settings = JSON.parse(JSON.stringify(this.settings))
            settings.options.account = this.previewAccount
            console.log('settings', settings)
            this.loading = true
            this.$api.post('/settings/:instance/contacts/user', settings).then(({data}) =>{
                this.loading = false
                this.$store.commit('UPDATE_USER_ALLSETTINGS',data)
            }).catch(err => {
				this.loading = false
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        }
    },
    mounted(){
    }
}
</script>

<style lang="scss">
    .templateCheckbox .ant-checkbox {
        top: 10px;
        left: 260px;
    }
</style>
