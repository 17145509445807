<template>
    <div>
        <a-modal :width="'550px'" centered :header="null" :footer="null" :visible="showSocialModal" @cancel="cancelSocial">
            <div>
                <h5>Add Social Profile</h5>
                <a-popover style="cursor: pointer;" trigger="click" overlayClassName="popoverStyle" placement="bottom">
                    <div slot="content">
                        <div class="popoverContent" v-for="(name,nameI) in social" :key="name+nameI" @click="newSocial.type = name">
                            {{name}}
                        </div>
                    </div>
                    <div style="padding-top: 5px; line-height: 1; width: 100px;">
                        <div style="margin-bottom: .5rem; color: #40454c;">{{newSocial.type}} <a-icon type="caret-down" class="ml-2" /></div>
                    </div>
                </a-popover>
                <a-form-model ref="newSocial" :model="newSocial">
                    <a-form-model-item prop="link" required :rules="req('Please enter the link')">
                        <a-input placeholder="Add Social" v-model="newSocial.link">

                        </a-input>
                    </a-form-model-item>
                </a-form-model>
                <div class="dF" style="justify-content:flex-end">
                    <a-button @click="cancelSocial" class="mr-3 cancel-button" size="large">CANCEL</a-button>
                    <a-button :loading="loading" @click="submitSocial" size="large" type="primary">{{update ? 'UPDATE':'CREATE'}}</a-button>
                </div>
            </div>
        </a-modal>

        <a-table :rowKey="(e) => e.id" style="overflow-y:scroll" class="white-table hide-scrollbar" :columns="columns" :data-source="account.socials">
            <div slot="name" slot-scope="obj">
                <img style="width:30px; height:30px" v-if="obj.type == 'Facebook'" :src="require('@/assets/Social_Icons/facebook.png')" />
                <img style="width:30px; height:30px" v-if="obj.type == 'LinkedIn'" :src="require('@/assets/Social_Icons/linkedin.png')" />
                <img style="width:30px; height:30px" v-if="obj.type == 'Twitter'" :src="require('@/assets/Social_Icons/twitter.png')" />
                <img style="width:30px; height:30px" v-if="obj.type == 'Google+'" :src="require('@/assets/Social_Icons/google+.png')" />
                <img style="width:30px; height:30px" v-if="obj.type == 'YouTube'" :src="require('@/assets/Social_Icons/youtube.png')" />
                <img style="width:30px; height:30px" v-if="obj.type == 'Instagram'" :src="require('@/assets/Social_Icons/instagram.png')" />
                <img style="width:30px; height:30px" v-if="obj.type == 'Pinterest'" :src="require('@/assets/Social_Icons/pinterest.png')" />
                <img style="width:30px; height:30px" v-if="obj.type == 'WeChat'" :src="require('@/assets/Social_Icons/wechat.png')" />
                <img style="width:30px; height:30px" v-if="obj.type == 'Quora'" :src="require('@/assets/Social_Icons/quora.png')" />
                <img style="width:30px; height:30px" v-if="obj.type == 'Foursquare'" :src="require('@/assets/Social_Icons/foursquare.png')" />
                <img style="width:30px; height:30px" v-if="obj.type == 'Klout'" :src="require('@/assets/Social_Icons/klout.png')" />
                <!-- <img style="width:30px; height:30px" v-if="obj.type == 'Gravatar'" :src="require('@/assets/Social_Icons/gravatar.png')" /> -->
            </div>
            <div slot="link" slot-scope="obj">
                <div class="dF aC">
                    <div class="mr-3">{{obj.link}}</div>
                    <svg @click="editSocial(obj)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit editIcon"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                </div>
            </div>
            <div slot="action" slot-scope="obj" class="dF jE">
                <svg @click="deleteSocial(obj)" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 deleteIcon"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
            </div>
        </a-table>

        <div class="dF aC" @click="addNewSocial" style="margin-top:24px; color:var(--orange); cursor:pointer; width: 100px;">
            <a-icon style="font-size:25px" type="plus-circle" class="mr-3"/>
            <div>Add more</div>
        </div>
    </div>
</template>

<script>
export default {
    props:{

    },
    computed:{
        account(){
            return this.$store.state.contacts.allSettings.userApp.options.account
        },
        settings(){
            return this.$store.state.contacts.allSettings.userApp
        }
    },
    data(){
        return{
            loading:false,
            newSocial:{
                type:'LinkedIn',
                link:''
            },
            social: [
                'LinkedIn',
                'Twitter',
                'Google+',
                'Facebook',
                'YouTube',
                'Instagram',
                'Pinterest',
                'WeChat',
                'Quora',
                'Foursquare',
                'Klout',
                // 'Gravatar',
                // 'Other'
            ],
            columns:[
                {
                    title: 'Social Icon',
                    key: 'name',
                    scopedSlots: { customRender: 'name' },
                },
                {
                    title: 'Links',
                    key: 'link',
                    scopedSlots: { customRender: 'link' },
                },
                {
                    title: '',
                    key: 'action',
                    scopedSlots: { customRender: 'action' },
                },
            ],
            showSocialModal:false,
            update:false,
        }
    },
    methods:{
        req:msg=>({required:true,message:msg}),
        addNewSocial(){
            this.update = false
            this.newSocial = {
                type:'LinkedIn',
                link:''
            }
            this.showSocialModal = true
        },
        submitSocial(){
            if (!this.update) {
                this.$refs.newSocial.validate(valid => {
                    if (valid) {
                        console.log('newSocial', this.newSocial)
                        let settings = JSON.parse(JSON.stringify(this.settings))
                        this.newSocial.id = Date.now()
						if (!settings.options.account.socials) {
							settings.options.account.socials = []
						}
                        settings.options.account.socials.push(this.newSocial)
                        this.loading = true
                        this.$api.post('/settings/:instance/contacts/user', settings).then(({data}) => {
                            console.log('data',data)
                            this.loading = false
                            this.showSocialModal = false
                            this.$store.commit('UPDATE_USER_ALLSETTINGS', data)
                            this.$store.commit('PREVIEW_ACCOUNT', settings.options.account)
                        }).catch(err => {
							this.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    } else {
                        console.log('error submit!!');
                    return false;
                    }
                })
            } else {
                this.$refs.newSocial.validate(valid => {
                    if (valid) {
                        console.log('updatinggg' , this.newSocial)
                        let settings = JSON.parse(JSON.stringify(this.settings))
                        let index = settings.options.account.socials.findIndex(x => x.id == this.newSocial.id)
                        settings.options.account.socials[index] = this.newSocial
                        this.loading = true
                        this.$api.post('/settings/:instance/contacts/user', settings).then(({data}) => {
                            console.log('data',data)
                            this.loading = false
                            this.showSocialModal = false
                            this.$store.commit('UPDATE_USER_ALLSETTINGS', data)
                            this.$store.commit('PREVIEW_ACCOUNT', settings.options.account)
                        }).catch(err => {
							this.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    } else {
                        console.log('error submit!!');
                    return false;
                    }
                })
            }
        },
        cancelSocial(){
			if(this.$refs.newSocial){
				this.$refs.newSocial.resetFields()
			}
            this.showSocialModal = false
        },
        editSocial(item){
            this.newSocial = JSON.parse(JSON.stringify(item))
            this.showSocialModal = true
            this.update = true
        },
        deleteSocial(item) {
            let self = this
            this.$confirm({
                title: "Delete Social",
                content: h => <div>Do you want to delete this Social Profile?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    let settings = JSON.parse(JSON.stringify(self.settings))
                    let account = JSON.parse(JSON.stringify(self.settings.options.account))
                    let index = account.socials.findIndex(x => x.id == item.id)
                    account.socials.splice(index,1)
                    settings.options.account = account
                    console.log('settings',settings)
                    console.log('deleteSocial', item)
                    self.$api.post('/settings/:instance/contacts/user', settings).then(({data}) => {
                        self.$store.commit('UPDATE_USER_ALLSETTINGS', data)
                        self.$store.commit('PREVIEW_ACCOUNT', settings.options.account)
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        }
    }
}
</script>

<style lang="scss">
    .popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }
</style>

<style lang="scss" scoped>
    .deleteIcon {
        stroke: #9EA0A5;
        cursor: pointer;
        transition:stroke .1s ease-in;
    }
    .deleteIcon:hover {
        stroke: var(--danger);
        transition:stroke .1s ease-in;
    }
    .popoverContent{
        height: 35px;
        width: 150px;
        line-height: 35px;
        padding-left: 10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
    }
    .editIcon {
        color: #9EA0A5;
        cursor: pointer;
        transition:color .1s ease-in;
    }
    .editIcon:hover {
        color: var(--orange);
        transition:color .1s ease-in;
    }
</style>
