<template>
    <div class="w-full mt-5">
        <div v-if="!selectedImage.url || selectedImage.url == ''">
            <div class="mb-3">{{instructions}}</div>
            <div class="relative" style="height:350px">
                <a-button @click="uploadFile" class="absolute" style="z-index:10; top:50%;left:50%; margin-top:-55px; margin-left:-81.61px;" type="primary" size="large">CHOOSE A FILE</a-button>
                <a-upload-dragger class="custom-upload" :beforeUpload="beforeUpload" :showUploadList="false">
                    <div class="mt-5" style="color:black">OR DRAG IT HERE</div>
                </a-upload-dragger>
            </div>
        </div>
        <div v-else class="dF jC w-full">
            <div style="width:320px">
                <div class="w-full dF jC">
                    <img style="max-width:300px; max-height:200px; object-fit:cover" :src="selectedImage.url" />
                </div>
                <div class="my-5 dF jSB">
                    <a-button @click="cancelImage" class="cancel-button" size="large" style="background-color:#ECE9F1; color:#3F3356">CANCEL</a-button>
                    <a-button @click="uploadFile" size="large" type="primary">CHOOSE NEW IMAGE</a-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getBase64} from 'bh-mod'
export default {
    props:{
        instructions:{
            type:String,
            default:''
        },
        image:{
            type:String,
            default:''
        }
    },
    data() {
        return{
            selectedImage:{}
        }
    },
    methods:{
		uploadFile(){
			this.$store.commit('SET_MULTIPLE_IMAGES', false)
			this.$store.commit('MEDIA_SELECT',{callback:this.selectFile,type:'images'})
		},
        cancelImage() {
            this.selectedImage = {}
            this.$emit('updateImage', this.selectedImage)
        },
        selectFile(item) {
            this.selectedImage = item
            this.$emit('updateImage', this.selectedImage)
        },
        beforeUpload(file) {
            console.log('FILEEEEEE', file)
            const isImage = file.type.includes('image')

            if (!isImage) {
                return this.$message.error('You can only upload an image file!');
            }

            const isLt2M = (file,max = 2) => file.size / 1024 / 1024 < max;

            if (!isLt2M(file,5) && isImage) {
                this.$message.error('Image must smaller than 5MB!');
                return false
            }
            getBase64(file, imageUrl => {
                this.selectedImage = {file,url:imageUrl,name:file.name};
                this.$emit('updateImage', this.selectedImage)
            });
            
            return false;
        },
    },
    created() {
        if (this.image != '') {
            this.selectedImage.url = this.image
        }
    }
}
</script>

<style lang="scss">
.custom-upload .ant-upload.ant-upload-drag{
    background-color:white !important;
    border:1px solid #D0C9D6 !important;
}
</style>
<style>
.cancel-button.ant-btn {
    border-color:#ECE9F1 !important;
    background-color:#ECE9F1; 
    color:#3F3356
}
</style>