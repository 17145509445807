<template>
    <div>
        <a-table :rowKey="(e) => e.id" class="white-table" :columns="columns" :data-source="Object.values(statuses)" :pagination="false">
            <div slot="name" slot-scope="obj" class="dF aC">
                <div class="mr-3">{{obj.name}}</div>
            </div>
            <div slot="action" slot-scope="obj" class="dF jE" style="gap: 20px;">
                <svg v-if="!obj.default" @click="editStatus(obj)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 editIcons"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                <i v-if="!obj.default || !obj.default" @click="$emit('deleteStatus', obj)" class="fe fe-trash-2 ml-3 actionIcons" style="font-size: 16px; "/>
                <i v-if="obj.default" class="fe fe-lock" />
            </div>
        </a-table>

		<div class="dF mt-4" style="gap: 50px; color:var(--primary);">
			<div @click="$emit('addNewStatus')" class="dF aC cursor-pointer">
				<a-icon style="font-size:25px" type="plus-circle" class="mr-2" />
				<span>Add Status</span>
			</div>

			<div @click="exportModal.visible = true" class="dF aC cursor-pointer">
				<a-icon style="font-size:25px" type="upload" class="mr-2" />
				<span>Export Statuses</span>
			</div>
		</div>

		<a-modal title="Export to Existing Project" :visible="exportModal.visible" okText="EXPORT"
			@cancel="exportModal.visible = false" @ok="exportCustomFields" centered>
			<a-row :gutter="16">
				<a-form-model ref="exportModal" :model="exportModal">
					<a-col :span="24">
						<a-form-model-item prop="instance" label="Project" required
							:rules="req('Please select the instance')">
							<a-select show-search :filter-option="filterOption" v-model="exportModal.instance" placeholder="Select a Project" size="large">
								<a-select-option v-for="inst in instances" :value="inst.id" :key="inst.id">{{ inst.name
								}}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
				</a-form-model>
			</a-row>
		</a-modal>
    </div>


</template>

<script>
export default {
    props:{

    },
    watch: {
    },
    computed:{
        statuses(){
            return this.$store.state.contacts.statuses
        },
		instance() {
			return this.$store.state.instance
		},
		instances() {
			return this.$store.state.instances.filter(x => x.id != this.instance.id && x.name).sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
		},
    },
    data(){
        return{
			exportModal: {
				visible: false,
				instance: null,
			},
            columns:[
                {
                    title: 'Name',
                    key: 'name',
                    scopedSlots: { customRender: 'name' },
                },

                {
                    title: 'Action',
                    key: 'action',
                    align: 'right',
                    scopedSlots: { customRender: 'action' },
                },
            ],
        }
    },
    methods:{
		req: msg => ({ required: true, message: msg }),

		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
		},

        editStatus(obj) {
            this.$store.commit('OPEN_EDIT_MODAL', {type:'leadStatus', item:obj})
        },

		exportCustomFields() {
			let self = this;
			this.$refs.exportModal && this.$refs.exportModal.validate(async (valid) => {
				if (valid) {
					self.$store.commit('LOAD', true)
					try {
						await self.$api.post(`lead-statuses/:instance/clone/${self.exportModal.instance}`)
						self.$store.commit('LOAD', false)
						self.$message.success('Successfully exported all the statuses into selected project.')
						self.exportModal = {
							visible: false,
							instance: null
						}
					} catch (err) {
						self.$store.commit('LOAD', false)
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err, `An error occurred while exporting statuses to another project. Please try again.`))
						}
					}
				} else {
					return false;
				}
			})
		},
    }
}
</script>

<style lang="scss" scoped>
    .actionIcons {
        color: #9EA0A5;
        cursor: pointer;
        transition:color .1s ease-in;
    }
    .actionIcons:hover {
        color: var(--danger);
        transition:color .1s ease-in;
    }
    .editIcons {
        color: #9EA0A5;
        cursor: pointer;
        transition:color .1s ease-in;
    }
    .editIcons:hover {
        color: var(--orange);
        transition:color .1s ease-in;
    }
</style>
