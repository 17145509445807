<template>
	<div class="w-full dF">
		<div class="mr-4">
			<div style="background-color: white; width: 225px;">
				<div class="custom-menu-style py-3">
					<div v-for="(item, itemI) in settingsMenu" :key="item + itemI" class="relative">
						<div class="menu-cat" style="font-weight: bold;">{{ item.label }}</div>
						<a-tooltip v-if="item.label == 'Customization'" overlayClassName="change-tooltip-color"
							class="absolute" style="left: 128px; top: 4px;">
							<template slot="title">
								Warning: If you delete any customizations, you will lose all the data associated with it
								inside your leads and opportunities.
							</template>
							<a-icon type="question-circle"
								style="line-height: 22.5px; margin-top: 5px; color: var(--orange);" />
						</a-tooltip>
						<div @click="handleClick({ key: child.id })" class="menu-item"
							:class="activeTab == child.id ? 'active' : ''" v-for="(child, childI) in item.children"
							:key="child + childI">{{ child.label }}</div>
						<hr v-if="itemI != settingsMenu.length - 1" />
					</div>
				</div>
			</div>
		</div>

		<div class="hide-scrollbar" style="flex: 1; overflow: hidden;">
			<div>
				<slot name="content" />
			</div>
		</div>
	</div>
</template>

<script>

export default {
	components: {
	},
	props: {
		activeTab: {
			default: 0,
			type: Number
		},
	},
	data() {
		return {
			settingsMenu: [
				{
					label: 'Email & Calendar Settings', id: 'sub1', children: [
						{ label: 'Integrations', id: 0 },
						{ label: 'Email Signature', id: 1 },
						{ label: 'Email Templates', id: 2 },
					]
				},
				{
					label: 'Customization', id: 'sub3', children: [
						{ label: 'Leads', id: 4 },
						{ label: 'Opportunities', id: 5 },
						{ label: 'Tasks', id: 6 },
						{ label: 'Meetings', id: 8 }
					]
				},
				{
					label: 'Data Management', id: 'sub2', children: [
						{ label: 'Manage Data', id: 3 }
					]
				},
			],
			timeNow: Date.now(),

		}
	},
	watch: {

	},
	computed: {
		instance() {
			return this.$store.state.instance
		},
		importLead() {
			return this.$store.state.contacts.importLead
		},
		settings() {
			return this.$store.state.contacts.allSettings.userApp
		}
	},
	methods: {
		handleClick(e) {
			this.$emit('menuclick', e.key)
		},
	},
	mounted() {

	},
	created() {
		if (this.importLead) this.activeTab = 3
	}

}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss" scoped>
// .contacts-page{
//     @media screen and (min-width:567px){
//         margin-top:2%;
//     }
// }
.menu-cat {
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

.menu-item {
	cursor: pointer;
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	transition: color 0.3s ease-in-out;
}

.menu-item:hover {
	color: #afafaf;
	transition: color 0.3s ease-in-out;
}

.menu-item.active {
	color: var(--orange);
	transition: color 0.3s ease-in-out;
}

.contacts-page {
	@media screen and (min-width:567px) {
		max-width: calc(100% - 35px);
	}
}

.aB {
	align-items: baseline;
}

.row-mb {
	[class^=ant-col] {
		margin-bottom: 20px;
	}
}

.more-option-icon {
	width: 35px;
	height: 35px;
	border-radius: 35px;
	text-align: center;
	background-color: transparent;
}

.more-option-icon:hover {
	color: var(--orange) !important;
	background-color: var(--light-gray);
}

.popoverContent {
	height: 35px;
	width: 160px;
	line-height: 35px;
	padding-left: 10px;
}

.popoverContent:hover {
	background-color: var(--off-white-light);
	cursor: pointer;
}
</style>


<style lang="scss">
.custom-menu-style .ant-menu-item-selected {
	margin: 0 !important;
	background-color: #FAF9F9 !important;
	color: var(--orange);
}

.custom-menu-style .ant-menu-item-selected svg {
	color: var(--orange);
}

.custom-menu-style .ant-menu-item {
	margin: 0 !important;
	height: 60px !important;
	display: flex;
	align-items: center;
}

.custom-menu-style .ant-menu-submenu-title {
	height: 60px !important;
	display: flex;
	align-items: center;
	color: #000 !important;
}

.custom-menu-style .ant-menu-submenu-selected {
	color: #000 !important;
}

.custom-menu-style .ant-menu-submenu-inline {
	border-bottom: 1px solid #E4E7EB
}

.custom-menu-style .ant-menu-item::after {
	border-right: none !important;
}
</style>
