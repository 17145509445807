<template>
    <div class="hide-scrollbar" style="overflow: scroll;">
        <CustomFieldsTable :data="fields" @addNewField="addNewField" @deleteField="deleteField" @editField="editField"/>
        <a-modal :width="'600px'" centered :header="null" :footer="null" :visible="showFieldModal" @cancel="cancelField">
            <BHLoading :show="loading" />
            <div>
                <h5>{{title}}</h5>
                <a-row :gutter="16" class="mt-5">
                    <a-form-model ref="newField" :model="newField">
                        <a-col :span="24">
                            <a-form-model-item prop="name" label="Name" required :rules="req('Please enter the Name')">
                                <a-input v-model="newField.name" size="large" placeholder="Enter the Name"></a-input>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24" class="mb-4">
                            <a-checkbox v-model="newField.required">Required</a-checkbox>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item prop="type" label="Field Type" required :rules="req('Please choose a type for your field')">
                                <a-select @change="typeSelected" v-model="newField.type" size="large">
                                    <a-select-option v-for="t in types" :key="t.value">{{t.label}}</a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24" v-if="newField.type == 'multiplechoice'">
                            <a-form-model-item>
                                <a-checkbox v-model="multiple">Allow Multiple Answers</a-checkbox>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <template v-if="newField.type === 'multiplechoice' || newField.type === 'checkbox'">
                                <a-form-model-item v-for="(option, optionI) in options" :key="optionI" :label="`Options #${optionI+1}`">
                                    <div class="dF aC" style="justify-content:space-between">
                                        <a-input @change="changeOption(option,optionI)" v-model="option.text" class="flex-1"></a-input>

                                        <a-icon class="ml-2" v-if="optionI == options.length-1" @click="addOptions(options[options.length-1])" style="font-size:25px; color: var(--orange)" type="plus-circle" />
                                        <a-icon class="ml-2" v-if="options.length > 2 && optionI != options.length -1 " @click="options.splice(optionI, 1)" style="font-size:25px; color: var(--orange)" type="minus-circle" />
                                    </div>
                                </a-form-model-item>
                            </template>
                        </a-col>
                        <a-col :span="24" v-if="newField.type == 'text'">
                            <a-form-model-item prop="value" label="Default Value">
                                <a-input v-model="newField.value" size="large" placeholder="Enter the Default Value"></a-input>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24" v-else-if="newField.type == 'number'">
                            <a-form-model-item prop="value" label="Default Value">
                                <a-input type="number" v-model="newField.value" size="large" placeholder="Enter the Default Value" :default-value="'0'"></a-input>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24" v-else-if="newField.type === 'multiplechoice' || newField.type === 'checkbox'">
                            <a-form-model-item prop="value" label="Default Value">
                                <a-select v-model="newField.value" size="large" placeholder="Select a Default Value" :mode="newField.type == 'checkbox' ? '':newField.type == 'multiplechoice' && multiple ? 'multiple':''">
                                    <a-select-option v-for="(value, valueI) in filterOptions(options)" :key="valueI" :value="valueI">{{value.text}}</a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>

                        <a-col :span="24" v-else-if="newField.type === 'date'">
                            <a-form-model-item prop="value" label="Default Value">
                                <a-date-picker style="width:100%" v-model="newField.value" size="large" placeholder="Select a Default Value" />
                            </a-form-model-item>
                        </a-col>
                    </a-form-model>
                </a-row>
                <a-row type="flex" class="mt-3">
                    <a-col :span="12">
                        <a-button @click="deleteField(newField)" size="large" type="danger" v-if="newField.id"><a-icon type="delete" />DELETE</a-button>
                    </a-col>
                    <a-col :span="12" style="text-align: right;">
                        <a-button @click="cancelField" size="large" class="cancel-button">CANCEL</a-button>
                        <a-button @click="submitField" type="primary" size="large" class="ml-3">{{newField.id? 'UPDATE':'CREATE'}}</a-button>
                    </a-col>
                </a-row>
            </div>
        </a-modal>
    </div>

</template>

<script>
import CustomFieldsTable from '@/components/contacts/CustomFieldsTable'
import BHLoading from 'bh-mod/components/common/Loading'
import moment from 'moment'

export default {
    components:{
        CustomFieldsTable,BHLoading
    },
    props:{

    },
    computed:{
        fields(){
            return Object.values(this.$store.state.contacts.fields)
        },
        settings(){
            return this.$store.state.contacts.allSettings.app
        },
        user(){
            return this.$store.state.user.user
        }
    },
    data(){
        return{
            showFieldModal:false,
            title:'Add Custom Field',
            newField: {
                name:'',
                required:false,
                type:'text',
                value:'',
            },
            loading:false,
            types:[
                {value:'text', label:'Text'},
                {value:'number', label:'Number'},
                {value:'checkbox', label:'Check Box'},
                {value:'multiplechoice', label:'Multiple Choice'},
                {value:'date', label:'Date (MM/DD/YYYY)'}
            ],
            options:[{
                text:''
            }],
            multiple:false,
        }
    },
    methods:{
        moment,
        filterOptions(array) {
            array = array.filter(x => {
                if (x.text != '') {
                    return x
                }
            })
            return array
        },
        changeOption(text, index) {
            if (text.text.length == 0) {
                this.options.splice(index,1)
            }
        },
        addOptions(lastOption){
            if (!lastOption.text) return this.$message.error('You need to fill in the field before adding a new one')
            else {
                let option = {
                    text:''
                }
                this.options.push(option)
            }
        },
        typeSelected(e) {
            if (e == 'text') {
                this.newField.value = ''
            } else if (e == 'number') {
                this.newField.value = 0
            } else if (e == 'checkbox' || e == 'multiplechoice') {
                this.newField.value = []
            } else if (e == 'date'){
                this.newField.value = this.moment(Date.now())
            }
        },
        req:msg=>({required:true,message:msg}),
        addNewField(){
            this.showFieldModal = true
        },
        cancelField(){
			if(this.$refs['newField']){
				this.$refs['newField'].resetFields()
			}
            this.newField = {
                name:'',
                required:false,
                type:'text',
                value:'',
            },
            this.title = 'Add Custom Field'
            this.showFieldModal = false
            this.options = [{
                text:''
            }]
        },
        submitField() {
            this.$refs.newField.validate(valid => {
                if (valid) {
                    let sendObj = {name:'', options:{}, type:'', required:false}
                    this.loading = true
                    if (this.newField.type === 'multiplechoice' || this.newField.type === 'checkbox') {
                        if (this.options.length == 1 || (this.options.length == 2 && this.options[1].text == '')){
                            this.loading = false
                            return this.$message.error('Requires at least two Choices')
                        }
                        sendObj.name = this.newField.name
                        sendObj.type = this.newField.type
                        sendObj.required = this.newField.required
                        sendObj.options = {
                            default:this.newField.value,
                            multipleChoices:this.options,
                            multipleAnswers:this.multiple,
                        }
                        sendObj.id = this.newField.id
                        sendObj.options.multipleChoices = sendObj.options.multipleChoices.map( x => x = x.text)
                        sendObj.user = this.user.id

                        if (this.title == 'Add Custom Field'){
                            this.$api.post(`/custom-fields/:instance`, sendObj).then( ({data}) => {
                                this.$store.commit('SET_PROP', {where:['fields',data.id],what:data})
                                this.loading = false
                                this.cancelField()
                            }).catch( err => {
                                this.$message.error(this.$err(err))
                                this.loading = false
                                this.cancelField()
                            })
                        } else if (this.title == 'Edit Custom Field'){
                            this.$api.put(`/custom-fields/:instance/${sendObj.id}`, sendObj).then( ({data}) => {
                                this.$store.commit('UPDATE_CUSTOM_FIELD',data)
                                this.loading = false
                                this.cancelField()
                            }).catch( err => {
                                this.$message.error(this.$err(err))
                                this.loading = false
                                this.cancelField()
                            })
                        }

                    } else if (this.newField.type == 'text' || this.newField.type == 'number' || this.newField.type == 'date') {
                        sendObj.id = this.newField.id
                        sendObj.name = this.newField.name
                        sendObj.type = this.newField.type
                        sendObj.required = this.newField.required
                        sendObj.options = {
                            default:this.newField.value,
                            multipleChoices:[],
                            multipleAnswers:false,
                        }
                        sendObj.user = this.user.id

                        if (this.title == 'Add Custom Field'){
                            this.$api.post(`/custom-fields/:instance`, sendObj).then( ({data}) => {
                                this.$store.commit('SET_PROP', {where:['fields',data.id],what:data})
                                this.loading = false
                                this.cancelField()
                            }).catch( err => {
                                this.$message.error(this.$err(err))
                                this.loading = false
                                this.cancelField()
                            })
                        } else if (this.title == 'Edit Custom Field'){
                            this.$api.put(`/custom-fields/:instance/${sendObj.id}`, sendObj).then( ({data}) => {
                                this.$store.commit('UPDATE_CUSTOM_FIELD',data)
                                this.loading = false
                                this.cancelField()
                            }).catch( err => {
                                this.$message.error(this.$err(err))
                                this.loading = false
                                this.cancelField()
                            })
                        }
                    }
                }
            })
        },
        deleteField(item) {
            let self = this
            this.$confirm({
                title: "Delete Field",
                content: h => <div>Do you want to delete this Field?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    self.$api.delete(`/custom-fields/:instance/${item.id}`).then( ({data}) => {
                        self.$store.commit('DELETE_CUSTOM_FIELD', data)
                        if (self.showFieldModal){
                            self.loading = false
                            self.cancelField()
                        }
                    }).catch(err => {
						self.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                }
            })
        },
        editField(item){
            this.title = 'Edit Custom Field'
            let newField = JSON.parse(JSON.stringify(item))
            newField.value = newField.type === 'number' ? Number(item.options.default) : newField.type === 'date' ? moment(item.options.default) : item.options.default
            this.options = newField.options.multipleChoices.map(x => x = {text: x})
            delete newField.options
			this.newField = JSON.parse(JSON.stringify(newField))
            this.showFieldModal = true
        },
    }
}
</script>

<style>

</style>
