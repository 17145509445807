import { render, staticRenderFns } from "./TagsTable.vue?vue&type=template&id=44c914ac&scoped=true"
import script from "./TagsTable.vue?vue&type=script&lang=js"
export * from "./TagsTable.vue?vue&type=script&lang=js"
import style0 from "./TagsTable.vue?vue&type=style&index=0&id=44c914ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44c914ac",
  null
  
)

export default component.exports