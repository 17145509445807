<template>
    <div>
        <h5 class="ml-3 mb-5">Email Accounts</h5>
        <div class="email-grid-display" >

            <div class="w-full h-full" style="border:1px solid #E4E7EB; border-radius:4px; text-align:center; padding:5%; display:flex; flex-direction: column; justify-content:space-between">
                <div><img :src="require('@/assets/google.png')" /></div>
                <h5 class="mt-3">Gmail</h5>
                <p>Connect to receive, send and sync your Google calendar to Bildhive. Once connected, Google Meet will act as your virtual conferencing provider, or you can connect with Zoom.</p>
                <template v-if="vendors.gmail && vendors.gmail.connectUrl && (!vendors.gmail.hasOwnProperty('active') || (vendors.gmail.active == false && vendors.gmail.hasOwnProperty('active')))">
                    <a :href="vendors.gmail.connectUrl">
                        <button type="button" class="login-with-google-btn text-center" :disabled="(vendors.outlook && vendors.outlook.connectUrl && vendors.outlook.hasOwnProperty('active') && vendors.outlook.active) || (vendors.office365 && vendors.office365.connectUrl && vendors.office365.hasOwnProperty('active') && vendors.office365.active)">
                            Sign in with Google
                        </button>
                    </a>
                </template>
                <template v-else-if="vendors.gmail && vendors.gmail.hasOwnProperty('active') && vendors.gmail.active" && vendors.gmail.id>
                    <div style="color:var(--success)">Status: Connected <span v-if="nylasAccount.email_address">({{ nylasAccount.email_address }})</span></div>
                    <a-button @click="disconnect" class="mt-3 disconnect-button" size="large">DISCONNECT</a-button>
                </template>
            </div>

            <div class="w-full h-full" style="border:1px solid #E4E7EB; border-radius:4px; text-align:center; padding:5%; display:flex; flex-direction: column; justify-content:space-between">
                <div>
                    <img :src="require('@/assets/outlook_1.png')" />
                </div>
                <h5 class="mt-3">Outlook</h5>
                <p>Connect to receive, send and sync your Outlook calendar to Bildhive. To use the virtual appointment scheduler connect with Zoom.</p>
                <template v-if="vendors.outlook && vendors.outlook.connectUrl &&  (!vendors.outlook.hasOwnProperty('active') || (vendors.outlook.active == false && vendors.outlook.hasOwnProperty('active')))">
					<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
						<template slot="title">Users accessing Outlook.com might encounter repetitive password prompts. For further information, refer to <a style="color: var(--primary);" href="https://support.microsoft.com/en-us/office/outlook-and-other-apps-are-unable-to-connect-to-outlook-com-when-using-basic-authentication-f4202ebf-89c6-4a8a-bec3-3d60cf7deaef" target="_blank"> link here.</a></template>
						<div>
							<button type="button" class="login-with-microsoft-btn text-center" :style="[{'background-image':`url(${require('@/assets/ms-icon.svg')})`}]" :disabled="true">
							Sign in with Microsoft
							</button>
						</div>
					</a-tooltip>

					<!-- Important Note: Revert Back this disable button changes one outlook issue is resolve. Remove above code and uncomment the below code. -->

					<!-- <a :href="vendors.outlook.connectUrl">
                        <button type="button" class="login-with-microsoft-btn text-center" :style="[{'background-image':`url(${require('@/assets/ms-icon.svg')})`}]" :disabled="(vendors.gmail && vendors.gmail.connectUrl && vendors.gmail.hasOwnProperty('active') && vendors.gmail.active) || (vendors.office365 && vendors.office365.connectUrl && vendors.office365.hasOwnProperty('active') && vendors.office365.active)">
                            Sign in with Microsoft
                        </button>
                    </a> -->
                </template>
                <template v-else-if="vendors.outlook && vendors.outlook.hasOwnProperty('active') && vendors.outlook.active" && vendors.outlook.id>
                    <div style="color:var(--success)">Status: Connected <span v-if="nylasAccount.email_address">({{ nylasAccount.email_address }})</span></div>
                    <a-button @click="disconnectOutlook" class="mt-3 disconnect-button" size="large">DISCONNECT</a-button>
                </template>
            </div>

            <div class="w-full h-full" style="border:1px solid #E4E7EB; border-radius:4px; text-align:center; padding:5%; display:flex; flex-direction: column; justify-content:space-between">
                <div><img :src="require('@/assets/office365.svg')" style="width: 64px; height: 64px;" /></div>
                <h5 class="mt-3">Office 365</h5>
                <p>Connect to receive, send and sync your Office 365 calendar to Bildhive. To use the virtual appointment scheduler connect with Teams or Zoom.</p>
                <template v-if="vendors.office365 && vendors.office365.connectUrl &&  (!vendors.office365.hasOwnProperty('active') || (vendors.office365.active == false && vendors.office365.hasOwnProperty('active')))">
                    <a :href="vendors.office365.connectUrl">
                        <button type="button" class="login-with-microsoft-btn text-center" :style="[{'background-image':`url(${require('@/assets/ms-icon.svg')})`}]" :disabled="(vendors.gmail && vendors.gmail.connectUrl && vendors.gmail.hasOwnProperty('active') && vendors.gmail.active) || (vendors.outlook && vendors.outlook.connectUrl && vendors.outlook.hasOwnProperty('active') && vendors.outlook.active)">
                            Sign in with Microsoft
                        </button>
                    </a>
                </template>
                <template v-else-if="vendors.office365 && vendors.office365.hasOwnProperty('active') && vendors.office365.active" && vendors.office365.id>
                    <div style="color:var(--success)">Status: Connected <span v-if="nylasAccount.email_address">({{ nylasAccount.email_address }})</span></div>
                    <a-button @click="disconnectOffice365" class="mt-3 disconnect-button" size="large">DISCONNECT</a-button>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed:{
        vendors() {
            return this.$store.state.contacts.vendors || {}
        },

		nylasAccount() {
            return this.$store.state.contacts.nylasAccount || {}
        }
    },
    methods:{
        disconnect() {
            let self = this
            this.$confirm({
                title: 'Disconnect Gmail',
                content: h => <div>Do you want to disconnect your Gmail Account <strong>{this.nylasAccount.email_address}</strong>?</div>,
                okText: 'DISCONNECT',
                okType: 'danger',
                cancelText: 'CANCEL',
                centered: true,
                onOk(){
                    console.log('disconnect gmail', self.vendors.gmail.id)
                    self.$api.delete(`/vendors/:instance/${self.vendors.gmail.id}`).then(({data}) => {
                        console.log('DATAAAAA COMING BACK', data)
                        self.$store.commit('UPDATE_GMAIL_VENDOR', data)
						window.location.reload();
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel(){
                    console.log('CANCELED')
                }
            })
        },
        disconnectOutlook() {
            let self = this
            this.$confirm({
                title: 'Disconnect Outlook',
                content: h => <div>Do you want to disconnect your Outlook Account <strong>{this.nylasAccount.email_address}</strong>?</div>,
                okText: 'DISCONNECT',
                okType: 'danger',
                cancelText: 'CANCEL',
                centered: true,
                onOk(){
                    console.log('disconnect outlook', self.vendors.outlook.id)
                    self.$api.delete(`/vendors/:instance/${self.vendors.outlook.id}`).then(({data}) => {
                        console.log('DATAAAAA COMING BACK', data)
                        self.$store.commit('UPDATE_OUTLOOK_VENDOR', data)
						window.location.reload();
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel(){
                    console.log('CANCELED')
                }
            })
        },
        disconnectOffice365() {
            let self = this
            this.$confirm({
                title: 'Disconnect Outlook',
                content: h => <div>Do you want to disconnect your Office 365 Account <strong>{this.nylasAccount.email_address}</strong>?</div>,
                okText: 'DISCONNECT',
                okType: 'danger',
                cancelText: 'CANCEL',
                centered: true,
                onOk(){
                    console.log('disconnect outlook', self.vendors.office365.id)
                    self.$api.delete(`/vendors/:instance/${self.vendors.office365.id}`).then(({data}) => {
                        console.log('DATAAAAA COMING BACK', data)
                        self.$store.commit('UPDATE_OFFICE365_VENDOR', data)
						window.location.reload();
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel(){
                    console.log('CANCELED')
                }
            })
        }
    }
}
</script>


<style lang="scss">
    .email-grid-display  {
        display: grid;
        gap: 1.5em;
        justify-content: center;
        align-items: center;

        @media screen and (min-width: 1100px){
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }
    .disconnect-button{
        color:#FFF !important;
        background-color:var(--danger) !important;
    }
    .disconnect-button:hover{
        border-color:var(--danger) !important;
    }

    .login-with-google-btn {
        transition: background-color .2s, box-shadow .2s !important;

        padding: 12px 16px 12px 42px !important;
        border: none !important;
        border-radius: 3px !important;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25) !important;

        color: #757575 !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif !important;

        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=) !important;
        background-color: white !important;
        background-repeat: no-repeat !important;
        background-position: 12px 50% !important;

        &:hover {
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25) !important;
        }

        &:active {
        background-color: #eeeeee !important;
        }

        &:focus {
        outline: none !important;
        box-shadow:
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc !important;
        }

        &:disabled {
        filter: grayscale(100%) !important;
        background-color: #ebebeb !important;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25) !important;
        cursor: not-allowed !important;
        }
    }

    .login-with-microsoft-btn {
        transition: background-color .2s, box-shadow .2s !important;

        padding: 12px 16px 12px 42px !important;
        border: none !important;
        border-radius: 3px !important;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25) !important;

        color: #757575 !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif !important;

        background-color: white !important;
        background-repeat: no-repeat !important;
        background-position: 12px 50% !important;

        &:hover {
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25) !important;
        }

        &:active {
        background-color: #eeeeee !important;
        }

        &:focus {
        outline: none !important;
        box-shadow:
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc !important;
        }

        &:disabled {
        filter: grayscale(100%) !important;
        background-color: #ebebeb !important;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25) !important;
        cursor: not-allowed !important;
        }
    }
</style>
