<template>
    <div class="w-full">
        <a-modal :visible="previewModal.visible" :centered="true" :footer="null" class="no-padding" @cancel="previewModal.visible = !previewModal.visible">
            <div style="padding:32px; background-color:#e8e7ed">
                <div style="background-color:#fff; padding:24px">
                    <div v-if="appointment.logo != ''" style="padding-bottom:24px">
                        <img style="max-width:100px; max-height: 100px; object-fit: cover;" :src="appointment.logo"></img>
                    </div>
                    <h4>Meeting confirmation</h4>
                    <div>Your appointment has been scheduled with {{`${user.firstName} ${user.lastName}`}}</div>
                    <div class="mt-3">Email Address</div>
                    <div>{{user.email}}</div>
                    <div class="mt-3">Date / Time</div>
                    <div>11/15/2022 05:00 PM (GMT-5:00) - Toronto</div>
                    <div class="mt-3">Location</div>
                    <div style="color:#1256cc; text-decoration: underline;">https://meet.google.com</div>
                    <div class="mt-3">Note: if you need to make changes to your meeting</div>

                    <div class="dF mt-3">
                        <div style="width:30%;">
                            <a-button size="large" style="background-color:#ECE9F1;">CANCEL</a-button>
                        </div>
                        <div style="width:70%;">
                            <a-button type="primary" size="large">RESCHEDULE</a-button>
                        </div>
                    </div>
                </div>
            </div>
        </a-modal>
        <BHLoading :show="loading" />

		<div class="dF aC jSB" style="font-size: 1rem;">
			<div class="dF aC" style="font-weight: bold;">
				<div class="mr-2">Meetings</div>
				<a-tooltip overlayClassName="change-tooltip-color">
					<template slot="title">
						By default, meeting scheduling works in a round robin fashion if there are multiple users logged in.
					</template>
					<a-icon type="question-circle" style="line-height: 22.5px; margin-top: 5px; color: var(--orange);" />
				</a-tooltip>
			</div>
			<div style="display: inline-block;">
				<div @click="resetSettings" class="dF aC mt-3 py-2 px-4" style="border-radius:4px; border:1px solid #ECEBED; background-color:white; cursor:pointer; ">
					<div class="mr-3">
						Reset Settings
					</div>
					<a-icon style="color:var(--danger)" type="delete" />
				</div>
			</div>
		</div>
        <div class="meeting-container">
            <div class="mt-6">
                <div style="font-weight: bold; font-size: 1rem;">When a meeting can be scheduled<span style="color: var(--danger);">*</span></div>
                <div>Set a period of rolling weeks or a custom ranges, people can book in your calendar</div>

				<a-radio-group v-model="meetingSetting.meetingScheduledType" class="mt-4">
					<a-radio value="rolling">
						Rolling Weeks
					</a-radio>
					<a-radio value="custom">
						Custom Date Range
					</a-radio>
				</a-radio-group>
				<div v-if="meetingSetting.meetingScheduledType === 'custom'" style="margin-top: 1em;">
					<div v-for="(date,index) in meetingSetting.meetingScheduleCustomDateRanges" :key="date+index" class="available-times dF aC" style="gap: 25px">
							<a-date-picker
								v-model="meetingSetting.meetingScheduleCustomDateRanges[index].startDate"
								:disabled-date="(e) => disabledStartDate(e, index)"
								valueFormat="YYYY-MM-DD"
								placeholder="Start Date"
								size="large"
							/>
							<a-date-picker
								v-model="meetingSetting.meetingScheduleCustomDateRanges[index].endDate"
								:disabled-date="(e) => disabledEndDate(e, index)"
								valueFormat="YYYY-MM-DD"
								placeholder="End Date"
								size="large"
							/>
						<div @click="deleteDateRange(index)" class="deleteIcon ml-4">
							<svg style="color:var(--danger)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 mr-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
						</div>
					</div>
					<div @click="addNewCustomRange" style="display: inline-block; color:var(--orange); cursor:pointer; margin-top:24px">
						<div class="dF aC">
							<a-icon style="font-size:25px" type="plus-circle" class="mr-3"/>
							<div>Add New Range</div>
						</div>
					</div>
				</div>

                <a-select
					v-else
                    v-model="meetingSetting.meetingScheduleRollingWeekSlot"
                    class="f1 w-full"
                    style="margin-top: 1em;"
                    size="large"
                >
                    <a-select-option :value="10080">This week</a-select-option>
                    <a-select-option :value="20160">Next week</a-select-option>
                    <a-select-option :value="30240">3 weeks</a-select-option>
                    <a-select-option :value="40320">4 weeks</a-select-option>
                    <a-select-option :value="50400">5 weeks</a-select-option>
                </a-select>
            </div>

			<div :class="meetingSetting.meetingScheduleRollingWeekSlot === 'custom' ? 'mt-3' : 'mt-6'">
                <div style="font-weight: bold; font-size: 1rem;">Select your preferred meeting type<span style="color: var(--danger);">*</span></div>

                <a-select
					mode="multiple"
                    v-model="meetingSetting.preferredMeetingType"
                    class="f1 w-full"
                    style="margin-top: 1em;"
                    size="large"
                >
                    <a-select-option value="virtualmeeting">Virtual Meeting</a-select-option>
                    <a-select-option value="phonemeeting">Phone Meeting</a-select-option>
                    <a-select-option value="salescentre">Sales Center</a-select-option>
                </a-select>
            </div>

			<div v-if="meetingSetting && meetingSetting.preferredMeetingType && meetingSetting.preferredMeetingType.includes('salescentre')" class="mt-6">
                <div style="font-weight: bold; font-size: 1rem;">Sales Center Location</div>

                <a-input
                    v-model="meetingSetting.location"
                    class="f1 w-full"
                    style="margin-top: 1em;"
                    size="large"
                >
                </a-input>
            </div>

			<div class="mt-6">
                <div style="font-weight: bold; font-size: 1rem;">Additional Notes</div>

                <a-textarea
                    v-model="meetingSetting.description"
                    class="f1 w-full"
                    style="margin-top: 1em;"
                    size="large"
                >
                </a-textarea>
            </div>

            <div class="mt-6">
                <div style="font-weight: bold; font-size: 1rem;">
					Available times<span style="color: var(--danger);">*</span>
				</div>
                <div>Choose the times of day you’ll accept sales meetings (for example 9:00 AM - 12:00 PM and 1:00 PM to 5:00 PM). Overlapping days will conflict your booking calendar.</div>


                <div v-for="(time,timeI) in meetingSetting.availableTimeSlots" :key="time+timeI" class="available-times dF aC jSB">
                    <a-select v-model="time.type" class="f1" size="large">
                        <a-select-option v-for="(day,dayI) in days" :key="day+dayI" :value="day.id">{{day.label}}</a-select-option>
                    </a-select>
                    <div class="px-3">from</div>
                    <a-time-picker v-model="time.from" use12-hours format="h:mm A" class="f1" size="large" :minute-step="15" />
                    <div class="px-3">to</div>
                    <a-time-picker v-model="time.to" use12-hours format="h:mm A" class="f1" size="large" :minute-step="15" />
					<div @click="copyTime(time)" class="copyIcon ml-4">
						<a-icon type="copy" style="font-size:18px" />
                    </div>
                    <div @click="deleteTime(timeI)" class="deleteIcon ml-4">
                        <svg style="color:var(--danger)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 mr-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                    </div>
                </div>

                <div @click="addNewTime" style="display: inline-block; color:var(--orange); cursor:pointer; margin-top:24px">
                    <div class="dF aC">
                        <a-icon style="font-size:25px" type="plus-circle" class="mr-3"/>
                        <div>Add hours</div>
                    </div>
                </div>
            </div>

            <div class="mt-6">
                <div style="font-weight: bold; font-size: 1rem;">Duration<span style="color: var(--danger);">*</span></div>

                <a-select
                    v-model="meetingSetting.durationSlots"
                    mode="multiple"
                    style="width: 100%; margin-top: 1em;"
                    size="large"
                    placeholder="Please select"
                    @change="durationChange"
                >
                    <a-select-option :value="15">15 minutes</a-select-option>
                    <a-select-option :value="30">30 minutes</a-select-option>
                    <a-select-option :value="45">45 minutes</a-select-option>
                    <a-select-option :value="60">1 hour</a-select-option>
                </a-select>
            </div>

            <div class="mt-6">
                <div style="font-weight: bold; font-size: 1rem;">Minimum notice time</div>
                <div>Minimum amount of time between booking a meeting and the meeting itself</div>

                <a-select
                    v-model="meetingSetting.minimumNoticeTimeSlot"
                    class="f1 w-full"
                    style="margin-top: 1em;"
                    size="large"
                >
                    <a-select-option :value="15">15 minutes</a-select-option>
                    <a-select-option :value="30">30 minutes</a-select-option>
                    <a-select-option :value="60">1 hour</a-select-option>
                    <a-select-option :value="120">2 hours</a-select-option>
                    <a-select-option :value="180">3 hours</a-select-option>
                </a-select>
            </div>

            <div class="mt-6">
                <div style="font-weight: bold; font-size: 1rem;">Buffer time</div>
                <div>Padding around your meetings you can’t be booked for</div>

                <a-select
                    v-model="meetingSetting.bufferTimeSlot"
                    class="f1 w-full"
                    style="margin-top: 1em;"
                    size="large"
                >
                    <a-select-option :value="0">No buffer</a-select-option>
					<a-select-option :value="5">5 minutes</a-select-option>
					<a-select-option :value="10">10 minutes</a-select-option>
                    <a-select-option :value="15">15 minutes</a-select-option>
                    <a-select-option :value="30">30 minutes</a-select-option>
                    <a-select-option :value="60">1 hour</a-select-option>
                </a-select>
            </div>

            <div class="mt-6">
                <div style="font-weight: bold; font-size: 1rem;">Pre-meeting reminder</div>

                <a-select
                    v-model="meetingSetting.preMeetingReminderSlot"
                    class="f1 w-full"
                    style="margin-top: 1em;"
                    size="large"
                >
                    <a-select-option :value="15">15 minutes before</a-select-option>
                    <a-select-option :value="30">30 minutes before</a-select-option>
                    <a-select-option :value="60">1 hour before</a-select-option>
                    <a-select-option :value="120">2 hours before</a-select-option>
                </a-select>
            </div>

			<div class="mt-6">
                <div style="font-weight: bold; font-size: 1rem;">Appointment Widget URL</div>

                <a-input
                    v-model="appointment.widgetUrl"
                    class="f1 w-full"
                    style="margin-top: 1em;"
                    size="large"
                >
                </a-input>
            </div>

            <div class="mt-8">
                <div style="font-weight: bold; font-size: 1rem;">Email notifications</div>
                <a-card class="w-full" style="margin-top: 1em;">
                    <div class="dF aS jSB" style="gap: 2em;">
                        <div>Send confirmation emails to prospects or customers immediately after they book meetings through this link</div>
                        <a-switch v-model="meetingSetting.meetingConfirmationToCustomer" />
                    </div>
					<div class="dF aS jSB mt-3" style="gap: 2em;">
                        <div>Send confirmation emails to sales reps immediately after any meetings book through this link.</div>
                        <a-switch v-model="meetingSetting.meetingConfirmationToSalesRep" />
                    </div>
                    <div class="mt-4">
                        <div style="font-weight: bold;">Logo</div>
                        <ImageBox class="mt-2" v-model="appointment.logo" :removable="true" />
                    </div>
					<div class="mt-3">
						<a-tooltip overlayClassName="change-tooltip-color">
							<template slot="title">
								The Sender Email Address identifies the address where the email is coming from.
								To add more addresses, go to <span><a
										:href="`https://email.bildhive.${$tld}/settings`" target="__blank"
										style="color: var(--orange);">Broadcast Email Builder</a></span> app.
							</template>
							<a-icon type="question-circle"
								style="line-height: 25px; position: absolute; margin-top: 1px; left: 220px; z-index: 2; color: var(--orange);" />
						</a-tooltip>
						<div style="font-weight: bold;">Select a Sender Email Address</div>
						<a-select v-model="appointment.sender" class="mt-2" size="large" style="width: 100%">
							<a-select-option value="">
								Default (no-reply@mail.bildhive.com)
							</a-select-option>
							<a-select-option v-for="(email, emailI) in senders" :value="email.id"
								:key="email + emailI">
								{{ email.email }}
							</a-select-option>
						</a-select>
                    </div>
                    <div class="mt-3">
                        <div style="font-weight: bold;">Subject<span style="color: var(--danger);">*</span></div>
                        <a-input v-model="appointment.subject" class="mt-2" placeholder="Subject" size="large" />
                    </div>
                    <div class="mt-4">
                        <div style="font-weight: bold;">Preheader<span style="color: var(--danger);">*</span></div>
                        <a-input v-model="appointment.preheader" class="mt-2" placeholder="Preheader" size="large" />
                    </div>
                    <div class="mt-5 dF aC" style="gap: 2em;">
                        <a-button @click="showPreview" style="background-color:#ECE9F1;" size="large">PREVIEW</a-button>
                        <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                            <template slot="title">Test emails will not have active links. For testing purposes only.</template>
                            <a-popover v-if="allowSendTest" trigger="click" :title="null" placement="bottomRight" :overlayStyle="{width:`400px`}">
                                <div slot="content">
                                    <a-form>
                                        <a-form-item label="Enter Emails">
                                            <div class="dF aC">
                                                <!-- use when sending to multiple emails @change="handleTestEmails"  -->
                                                <a-select :token-separators="[',']" v-model="emails" placeholder="john@doe.com, jill@hive.com..." style="width:calc(100%-30px);">
                                                    <a-select-option v-for="(user) in users" :value="user.email" :key="user.id">
                                                    	{{ user.firstName + ' ' + user.lastName }} {{ `(${user.email})` }}
                                                    </a-select-option>
                                                </a-select>
                                                <div style="width:30px;">
                                                    <a-button :loading="sendingTest" type="primary" icon="mail" @click="sendTest"/>
                                                </div>
                                            </div>
                                        </a-form-item>
                                    </a-form>
                                </div>
                                <a-button style="background-color:#ECE9F1;" size="large">SEND TEST EMAIL</a-button>
                            </a-popover>
                        </a-tooltip>
                    </div>

                    <hr class="mt-5 mb-4" style="margin-left: -24px; margin-right: -24px;" />

                    <div class="dF aS jSB" style="gap: 2em;">
                        <div>Send pre-meeting reminder emails to prospects or customers who book meetings through this link.</div>
                        <a-switch v-model="meetingSetting.preMeetingReminderToCustomer" />
                    </div>

					<div class="dF aS jSB mt-3" style="gap: 2em;">
                        <div>Send pre-meeting reminder emails to sales reps when any meetings book through this link.</div>
                        <a-switch v-model="meetingSetting.preMeetingReminderToSalesRep" />
                    </div>
                </a-card>
            </div>
        </div>
        <div class="dF jE aC mt-8">
            <a-button @click="cancelChanges" class="mr-4" style="background-color:#ECE9F1;" size="large">CANCEL</a-button>
            <a-button @click="saveChanges" type="primary" size="large">SAVE CHANGES</a-button>
        </div>
    </div>

</template>

<script>

import moment from 'moment'
import ImageBox from '@/components/common/ImageBox'
import BHLoading from 'bh-mod/components/common/Loading'
import {validateEmail} from 'bh-mod'

export default {
    components:{ImageBox,BHLoading},
    computed:{
        user() {
            return this.$store.state.user.user
        },
        meetingSettings(){
            return this.$store.state.contacts.allSettings.userApp.options.meetingSettings
        },
        settings() {
            return this.$store.state.contacts.allSettings.app
        },
        users() {
            return this.$store.state.contacts.users
        },
        allowSendTest() {
            let vendors = this.$store.state.contacts.vendors

            if (vendors && ((vendors.gmail && vendors.gmail.active) || (vendors.office365 && vendors.office365.active) || (vendors.outlook && vendors.outlook.active))) return true
            else return false
        }
    },
    data() {
        return {
			sendingTest:false,
            loading:false,
            meetingSetting:{},
            days:[
                {label:'Everyday', id:'Everyday'},
                {label:'Mon - Fri', id:'Mon - Fri'},
                {label:'Sat - Sun', id:'Sat - Sun'},
                {label:'Sunday', id:'Sunday'},
                {label:'Monday', id:'Monday'},
                {label:'Tuesday', id:'Tuesday'},
                {label:'Wednesday', id:'Wednesday'},
                {label:'Thursday', id:'Thursday'},
                {label:'Friday', id:'Friday'},
                {label:'Saturday', id:'Saturday'},
            ],
			appointment: {
				widgetUrl:'',
				logo: '',
				sender:'',
				subject:'',
				preheader:''
			},
            previewModal:{
                visible:false
            },
            emails:'',
			senders: [],
        }
    },
    methods:{
        sendTest() {
            let sendObj = {
                mjml:'',
                subject:'',
                to:''
            }
            sendObj.mjml = `<mjml>
				<mj-body>
					<mj-section background-color="#e8e7ed" padding="30px 30px">
						${this.appointment.logo && this.appointment.logo != '' ?`
					<mj-column background-color="#FFF" width="100%">
						<mj-image padding-top="20px" align="left" width="100px" src="${this.appointment.logo}"></mj-image>
					</mj-column>`:''}
					<mj-group background-color="#FFF" width="100%">
						<mj-column>
						<mj-text font-family="Arial" font-size="16px" >
							<h1 style="margin-bottom:0px">Meeting confirmation</h1>
						</mj-text>
						<mj-text line-height="1.5">
							<div>Your appointment has been scheduled with ${this.user.firstName} ${this.user.lastName}</div>
							<div style="padding-top:20px">Email Address</div>
							<div>${this.user.email}</div>
							<div style="padding-top:20px">Date / Time</div>
							<div>11/15/2022 05:00 PM (GMT-5:00) - Toronto</div>
							<div style="padding-top:20px">Location</div>
							<a style="color:#FF9027; text-decoration:underline">https://meet.google.com</a>
							<div style="padding-top:20px">Note: if you need to make changes to your meeting</div>
						</mj-text>
						</mj-column>
					</mj-group>

					<mj-column background-color="#FFF" width="30%" padding-bottom="20px">
						<mj-button align="left" background-color="#ECE9F1" color="#000">CANCEL</mj-button>
					</mj-column>
					<mj-column background-color="#FFF" width="70%" padding-bottom="20px">
						<mj-button align="left" background-color="#FF9027" color="#FFF">RESCHEDULE</mj-button>
					</mj-column>
					</mj-section>
				</mj-body>
				</mjml>`
            sendObj.subject = 'Meeting confirmation'
            sendObj.to = this.emails
			this.sendingTest = true

            this.$api.post(`/contacts/:instance/sendmail`, sendObj).then(({data}) => {
                this.emails = ''
                if (data && data.success) {
                    this.$notification['success']({
                        message: 'Email Sent',
                        description: 'Your email has been sent successfully.',
                        duration: 4
                    });
					this.sendingTest = false
                } else {
                    this.$notification['error']({
                        message: 'Error',
                        description: 'Something went wrong. Try again',
                        duration: 4
                    });
					this.sendingTest = false
                }
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        showPreview() {
            this.previewModal.visible = true
        },
        async saveChanges(){
            let sendObj = JSON.parse(JSON.stringify(this.meetingSetting))

			if(sendObj.meetingScheduledType === 'custom'){
				sendObj.meetingScheduleCustomDateRanges = sendObj.meetingScheduleCustomDateRanges.filter(cd => cd.startDate && cd.endDate)
				if (sendObj.meetingScheduleCustomDateRanges.length == 0) return this.$message.error('Please select at least one custom date range.')
			} else {
				sendObj.meetingScheduleCustomDateRanges = [];
				if (!sendObj.meetingScheduleRollingWeekSlot) return this.$message.error('Please select at least one rolling week slot.')
			}

			if (!sendObj.preferredMeetingType || sendObj.preferredMeetingType.length == 0) return this.$message.error('Select at least one preferred meeting type.')

            if (sendObj.availableTimeSlots && sendObj.availableTimeSlots.length) {
				let invalid = false
                sendObj.availableTimeSlots.forEach(x => {
                    x.from = moment(x.from).format('HH:mm')
                    x.to = moment(x.to).format('HH:mm')
                    if (x.from == 'Invalid date' || x.to == 'Invalid date' || x.type == ''){
                        invalid = true
                    }
                })
				if (invalid) return this.$message.error('Please select valid time slots.')
            } else {
				return this.$message.error('Please select at least one available time slot.')
			}

            if (!sendObj.durationSlots || sendObj.durationSlots.length == 0){
                return this.$message.error('Please select at least one duration slot.')
            }

            this.loading = true
            await this.$api.post('/contacts/:instance/meeting-settings', sendObj).then(data => {
                this.$store.commit('UPDATE_MEETING_SETTINGS', data.data)
                this.$message.success('Meeting settings saved successfully.')
            }).catch(err => {
                this.loading = false
            })

			let settings = JSON.parse(JSON.stringify(this.$store.state.contacts.allSettings.app))
			settings.options.appointments = this.appointment
			await this.$api.post('/settings/:instance/contacts', settings).then( ({data}) => {
				this.$store.commit('UPDATE_ALLSETTINGS', data)
				this.loading = false
				this.onClose()
			}).catch(err => {
				this.loading = false
			})
        },
		resetSettings(){
			let self = this;
			this.$confirm({
				title: 'Reset Settings',
				content: 'Are you sure you want to reset all settings?',
				okText: 'RESET',
				okType: 'danger',
				centered: true,
				cancelText: 'CANCEL',
				onOk: () => {
					self.confirmResetSettings()
				},
					onCancel() {
				},
			});
		},
		async confirmResetSettings(){
			let sendObj = {
				availableTimeSlots:[],
				durationSlots:[],
				meetingScheduleRollingWeekSlot:null,
				minimumNoticeTimeSlot:null,
				bufferTimeSlot:null,
				startTimeIncrementSlot:null,
				preMeetingReminderSlot:null,
				meetingConfirmationToCustomer:true,
				meetingConfirmationToSalesRep: false,
				preMeetingReminderToCustomer:false,
				preMeetingReminderToSalesRep: false,
				meetingScheduledType: "rolling",
				meetingScheduleCustomDateRanges: [],
				preferredMeetingType: [],
				location: '',
				description: ''
			}

			this.loading = true
            await this.$api.post('/contacts/:instance/meeting-settings', sendObj).then(data => {
                this.$store.commit('UPDATE_MEETING_SETTINGS', data.data)
				this.meetingSetting = data.data
                this.$message.success('Meeting settings have been reset successfully.')
            }).catch(err => {
                this.loading = false
            })

			let settings = JSON.parse(JSON.stringify(this.$store.state.contacts.allSettings.app))
			settings.options.appointments = this.appointment
			await this.$api.post('/settings/:instance/contacts', settings).then( ({data}) => {
				this.$store.commit('UPDATE_ALLSETTINGS', data)
				this.loading = false
				this.onClose()
			}).catch(err => {
				this.loading = false
			})
		},
        cancelChanges(){
            this.meetingSetting = JSON.parse(JSON.stringify(this.meetingSettings))
            if (this.meetingSetting.availableTimeSlots && this.meetingSetting.availableTimeSlots.length) {
                this.meetingSetting.availableTimeSlots.forEach(x => {
                    x.from = moment(x.from, 'HH:mm')
                    x.to = moment(x.to, 'HH:mm')
                })
            }
            this.appointment.logo = this.settings && this.settings.options && this.settings.options.appointments && this.settings.options.appointments &&  this.settings.options.appointments.logo || ''
        },
        updateImage(data) {
            this.appointment.logo = data
        },
        durationChange(){
            this.meetingSetting.durationSlots = this.meetingSetting.durationSlots.sort((a,b)=>{
                return a - b
            })
        },
        moment,
        addNewTime(){
            let newTime = {
                id:Date.now(),
                from:null,
                to:null,
                type:''
            }
            this.meetingSetting.availableTimeSlots.push(newTime)
        },
        deleteTime(time){
            this.meetingSetting.availableTimeSlots.splice(time, 1)
        },
		copyTime(time){
			const newTime = {
				...time,
                id:Date.now(),
            }
            this.meetingSetting.availableTimeSlots.push(newTime)
        },
		addNewCustomRange(){
            this.meetingSetting.meetingScheduleCustomDateRanges.push({
                startDate:null,
                endDate:null,
            })
        },
		deleteDateRange(date){
            this.meetingSetting.meetingScheduleCustomDateRanges.splice(date, 1)
        },
		disabledStartDate(startValue, index) {
			const endValue = this.meetingSetting.meetingScheduleCustomDateRanges[index].endDate && moment(this.meetingSetting.meetingScheduleCustomDateRanges[index].endDate);
			if (!startValue || !endValue) {
				return false;
			}
			return startValue.valueOf() > endValue.valueOf();
		},
		disabledEndDate(endValue, index) {
			const startValue = this.meetingSetting.meetingScheduleCustomDateRanges[index].startDate && moment(this.meetingSetting.meetingScheduleCustomDateRanges[index].startDate);
			if (!endValue || !startValue) {
				return false;
			}
			return startValue.valueOf() >= endValue.valueOf();
		},
    },
	created() {
		let meetingObj = JSON.parse(JSON.stringify(this.meetingSettings))
		if (meetingObj?.availableTimeSlots?.length) {
			meetingObj.availableTimeSlots.forEach(x => {
				let startTime = new Date()
				let endTime = new Date()

				startTime.setHours(parseInt(x.from.split(':')[0]))
				startTime.setMinutes(parseInt(x.from.split(':')[1]))

				endTime.setHours(parseInt(x.to.split(':')[0]))
				endTime.setMinutes(parseInt(x.to.split(':')[1]))

				x.from = this.moment(startTime)
				x.to = this.moment(endTime)
			})
		}
		if (!meetingObj.meetingConfirmationToSalesRep) {
			meetingObj.meetingConfirmationToSalesRep = false;
		}
		if (!meetingObj.preMeetingReminderToSalesRep) {
			meetingObj.preMeetingReminderToSalesRep = false;
		}
		if (!meetingObj.meetingScheduledType) {
			meetingObj.meetingScheduledType = 'rolling';
		}
		if (!meetingObj.meetingScheduleCustomDateRanges) {
			meetingObj.meetingScheduleCustomDateRanges = [{ startDate: null, endDate: null }];
		}
		this.meetingSetting = meetingObj
		let settings = JSON.parse(JSON.stringify(this.$store.state.contacts.allSettings.app))
		if (settings?.options?.appointments) {
			this.appointment = settings.options.appointments;
		}

		this.$api.get(`/senders/:instance`).then(({ data }) => {
			this.senders = data
		}).catch(err => {
			if (err?.response?.status !== 400) {
				this.$message.error(this.$err(err))
			}
		})
	}
}
</script>

<style lang="scss">
    .no-padding .ant-modal-body{
        padding:0px !important;
    }
</style>
<style lang="scss" scoped>

    .editIcon {
        color: #9EA0A5;
        cursor: pointer;
        transition:color .1s ease-in;
    }
    .editIcon:hover {
        color: var(--orange);
        transition:color .1s ease-in;
    }
    .deleteIcon {
        color: #9EA0A5;
        cursor: pointer;
        transition:color .1s ease-in;
    }
    .deleteIcon:hover {
        color: var(--danger);
        transition:color .1s ease-in;
    }
	.copyIcon:hover {
		cursor: pointer;
        transition:color .1s ease-in;
    }
    .available-times {
        margin-top: 1em;
    }

    @media screen and (min-width: 1840px) {
        .meeting-container{
            width:40%;
        }
    }
    @media screen and (max-width: 1840px) {
        .meeting-container{
            width:60%;
        }
    }
    @media screen and (max-width: 1400px) {
        .meeting-container{
            width:80%;
        }
    }
    @media screen and (max-width: 1024px) {
        .meeting-container{
            width:100%;
        }
    }
</style>
