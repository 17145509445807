<template>
    <div class="hide-scrollbar" style="overflow: scroll;">
        <template v-if="data.options && data.options.stages && data.options.stages.list">
            <a-table :pagination="false" :rowKey="(e) => e.id" class="white-table" :columns="columns" :data-source="data.options.stages.list">
                <div slot="name" slot-scope="obj" class="dF aC">
                    <div class="mr-5">{{obj.name}}</div>
                </div>

                <div slot="color" slot-scope="obj">
                    <div class="dF">
                        <div @click="$emit('changecolor', obj, 'var(--purple)')" style="margin: 0 5px 0 5px; width:20px; height:20px; background-color:var(--purple); text-align:center; cursor: pointer;"><a-icon style="color:white" v-if="obj.color === 'var(--purple)'" type="check" /></div>
                        <div @click="$emit('changecolor', obj, 'var(--orange)')" style="margin: 0 5px 0 5px; width:20px; height:20px; background-color:var(--orange); text-align:center; cursor: pointer;"><a-icon style="color:white" v-if="obj.color === 'var(--orange)'" type="check" /></div>
                        <div @click="$emit('changecolor', obj, 'var(--yellow)')" style="margin: 0 5px 0 5px; width:20px; height:20px; background-color:var(--yellow); text-align:center; cursor: pointer;"><a-icon style="color:white" v-if="obj.color === 'var(--yellow)'" type="check" /></div>
                        <div @click="$emit('changecolor', obj, 'var(--cyan)')" style="margin: 0 5px 0 5px; width:20px; height:20px; background-color:var(--cyan); text-align:center; cursor: pointer;"><a-icon style="color:white" v-if="obj.color === 'var(--cyan)'" type="check" /></div>
                        <div @click="$emit('changecolor', obj, 'var(--teal)')" style="margin: 0 5px 0 5px; width:20px; height:20px; background-color:var(--teal); text-align:center; cursor: pointer;"><a-icon style="color:white" v-if="obj.color === 'var(--teal)'" type="check" /></div>
                        <div @click="$emit('changecolor', obj, 'var(--danger)')" style="margin: 0 5px 0 5px; width:20px; height:20px; background-color:var(--danger); text-align:center; cursor: pointer;"><a-icon style="color:white" v-if="obj.color === 'var(--danger)'" type="check" /></div>
                        <div @click="$emit('changecolor', obj, '#3F3356')" style="margin: 0 5px 0 5px; width:20px; height:20px; background-color:#3F3356; text-align:center; cursor: pointer;"><a-icon style="color:white" v-if="obj.color === '#3F3356'" type="check" /></div>
                        <div @click="$emit('changecolor', obj, 'var(--black)')" style="margin: 0 5px 0 5px; width:20px; height:20px; background-color:var(--black); text-align:center; cursor: pointer;"><a-icon style="color:white" v-if="obj.color === 'var(--black)'" type="check" /></div>
                        <div @click="$emit('changecolor', obj, 'var(--gray)')" style="margin: 0 5px 0 5px; width:20px; height:20px; background-color:var(--gray); text-align:center; cursor: pointer;"><a-icon style="color:white" v-if="obj.color === 'var(--gray)'" type="check" /></div>
                    </div>
                </div>

                <div slot="probability" slot-scope="obj">
                    <div v-if="!predefinedStages.includes(obj.id)" style="display:inline-block">
                        <a-popover trigger="click" @click="clickPopover(obj)">
                            <template slot="content">
                                <a-input type="number" v-model="prob" addon-after="%" size="large" :min="0" :max="100" />
                                <div class="dF jE">
                                    <a-button @click="saveProbability(obj)" class="mt-3" type="primary" size="large">SAVE</a-button>
                                </div>
                            </template>
                            <div class="dF aC" style="cursor:pointer">
                                <div class="mr-3">{{!obj.hasOwnProperty('probability') && !obj.probability ? `${20}%`:`${obj.probability}%`}}</div>
                                <a-icon type='caret-down' />
                            </div>
                        </a-popover>
                    </div>
                </div>

                <div slot="action" slot-scope="obj" class="dF jE" style="gap: 20px;">
					<div v-if="!predefinedStages.includes(obj.id)">
						<svg @click="$emit('editStage', obj)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 editIcon mr-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
					</div>
                    <template v-if="![...predefinedStages, 'paid_reservation', 'completed_reservation'].includes(obj.id) && data.options.stages.list.length !== 2 && data.options && data.options.stages && data.options.stages.list">
                        <i @click="$emit('deleteStage', obj)" class="fe fe-trash-2 deleteIcon" style="font-size: 16px; "/>
                    </template>
					<template v-else>
						<i class="fe fe-lock" />
					</template>
                </div>
            </a-table>
        </template>

		<div class="dF mt-4" style="gap: 50px; color:var(--primary);">
			<div @click="$emit('addNewStage')" class="dF aC cursor-pointer">
				<a-icon style="font-size:25px" type="plus-circle" class="mr-2" />
				<span>Add Stage</span>
			</div>

			<div @click="exportModal.visible = true" class="dF aC cursor-pointer">
				<a-icon style="font-size:25px" type="upload" class="mr-2" />
				<span>Export Stages</span>
			</div>
		</div>

		<a-modal title="Export to Existing Project" :visible="exportModal.visible" okText="EXPORT"
			@cancel="exportModal.visible = false" @ok="exportStages" centered>
			<a-row :gutter="16">
				<a-form-model ref="exportModal" :model="exportModal">
					<a-col :span="24">
						<a-form-model-item prop="instance" label="Project" required
							:rules="req('Please select the instance')">
							<a-select show-search :filter-option="filterOption" v-model="exportModal.instance" placeholder="Select a Project" size="large">
								<a-select-option v-for="inst in instances" :value="inst.id" :key="inst.id">{{ inst.name
								}}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
				</a-form-model>
			</a-row>
		</a-modal>
    </div>
</template>

<script>
export default {
    props:['data'],
    watch:{
        data:{
            handler(val) {
                val.options.stages.list.sort((a,b) => {
                    if (!this.predefinedStages.includes(a.stage) && !this.predefinedStages.includes(b.stage)) {
                        if (a.probability > b.probability) return 1
                        if (a.probability < b.probability) return -1
                        else return 0
                    }
                })
            }
        }
    },
    data(){
        return{
            prob:0,
			predefinedStages: ['transaction', 'won', 'lost'],
			exportModal: {
				visible: false,
				instance: null,
			},
            columns:[
                {
                    title: 'Name',
                    key: 'name',
                    scopedSlots: { customRender: 'name' },
                },
                {
                    title: 'Color Label',
                    key: 'color',
                    scopedSlots: { customRender: 'color' },
                },
                {
                    title: 'Win Probability',
                    key: 'probability',
                    scopedSlots: { customRender: 'probability' },
                },
                {
                    title: 'Actions',
                    key: 'action',
                    align: 'right',
                    scopedSlots: { customRender: 'action' },
                },
            ],
        }
    },
    methods:{
		req: msg => ({ required: true, message: msg }),

		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
		},

        saveProbability(obj) {
            if (this.settings.options && this.settings.options.stages && this.settings.options.stages.list) {
                let settings = JSON.parse(JSON.stringify(this.settings))
                let list = settings.options.stages.list
                let index = list.findIndex(x => x.id == obj.id)
                if (index != -1) {
                    let obj = {}
                    obj = list[index]
                    obj.probability = Number(this.prob)
                }
                settings.options.stages.list = list
                this.$api.post('/settings/:instance/contacts', settings).then( ({data}) => {
                    this.$store.commit('UPDATE_ALLSETTINGS', data)
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            }
        },
        clickPopover(obj){
            if (obj.probability) this.prob = parseInt(obj.probability)
            else this.prop = 20
        },

		exportStages() {
			let self = this;
			this.$refs.exportModal && this.$refs.exportModal.validate(async (valid) => {
				if (valid) {
					self.$store.commit('LOAD', true)
					try {
						await self.$api.post(`settings/:instance/contacts/clone-settings`, {
							targetInstance: self.exportModal.instance,
							keysToClone: ["stages"]
						})
						self.$store.commit('LOAD', false)
						self.$message.success('Successfully exported all the stages into selected project.')
						self.exportModal = {
							visible: false,
							instance: null
						}
					} catch (err) {
						self.$store.commit('LOAD', false)
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err, `An error occurred while exporting stages to another project. Please try again.`))
						}
					}
				} else {
					return false;
				}
			})
		},
    },
    computed:{
        settings(){
            return this.$store.state.contacts.allSettings.app
        },
		instance() {
			return this.$store.state.instance
		},
		instances() {
			return this.$store.state.instances.filter(x => x.id != this.instance.id && x.name).sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
		},
    },
    created() {
        if (this.data.options && this.data.options.stages && this.data.options.stages.list) {
            this.data.options.stages.list.sort((a,b) => {
                if (!this.predefinedStages.includes(a.stage) && !this.predefinedStages.includes(b.stage)) {
                    if (a.probability > b.probability) return 1
                    if (a.probability < b.probability) return -1
                    else return 0
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .editIcon {
        color: #9EA0A5;
        cursor: pointer;
        transition:color .1s ease-in;
    }
    .editIcon:hover {
        color: var(--orange);
        transition:color .1s ease-in;
    }
    .deleteIcon {
        color: #9EA0A5;
        cursor: pointer;
        transition:color .1s ease-in;
    }
    .deleteIcon:hover {
        color: var(--danger);
        transition:color .1s ease-in;
    }
</style>
