<template>
    <div>
        <a-table :pagination="false" :rowKey="(e) => e.id" class="white-table" :columns="columns" :data-source="data">
            <div slot="type" slot-scope="obj" class="dF aC">
                {{obj.type === 'multiplechoice'?'Multiple Choice' : obj.type === 'text'?'Text' : obj.type === 'checkbox'?'Checkbox' : ''}}
            </div>
            <div slot="default" slot-scope="obj" class="dF aC">
                <template v-if="typeof obj.options.default == 'object'">
                    <div :class="optionI != 0 ? 'ml-2' :'' " class="px-2 py-1" style="border-radius:4px; background-color:#ECE9F1" v-for="(option, optionI) in obj.options.default" :key="optionI">{{obj.options.multipleChoices[option]}}</div>
                </template>
                <template v-else>
                    <div v-if="obj.options.multipleChoices && obj.options.multipleChoices.length">{{obj.options.multipleChoices[obj.options.default]}}</div>
                    <div v-else>{{obj.options.default}}</div>
                </template>
            </div>
            <div slot="type" slot-scope="obj">
                {{obj.type.charAt(0).toUpperCase() + obj.type.slice(1)}}
            </div>
            <div slot="createdAt" slot-scope="obj" class="dF aC" style="color: #9EA0A5;">
                {{convertDate(obj.createdAt)}}
            </div>
            <div slot="action" slot-scope="obj" class="dF jE">
                <svg @click="$emit('editField', obj)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-3 editIcon"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                <i @click="$emit('deleteField', obj)" class="fe fe-trash-2 deleteIcon" style="font-size: 16px; "/>
            </div>
        </a-table>

		<div class="dF mt-4" style="gap: 50px; color:var(--primary);">
			<div @click="$emit('addNewField')" class="dF aC cursor-pointer">
				<a-icon style="font-size:25px" type="plus-circle" class="mr-2" />
				<span>Add Custom Field</span>
			</div>

			<div @click="exportModal.visible = true" class="dF aC cursor-pointer">
				<a-icon style="font-size:25px" type="upload" class="mr-2" />
				<span>Export Custom Fields</span>
			</div>
		</div>

		<a-modal title="Export to Existing Project" :visible="exportModal.visible" okText="EXPORT"
			@cancel="exportModal.visible = false" @ok="exportCustomFields" centered>
			<a-row :gutter="16">
				<a-form-model ref="exportModal" :model="exportModal">
					<a-col :span="24">
						<a-form-model-item prop="instance" label="Project" required
							:rules="req('Please select the instance')">
							<a-select show-search :filter-option="filterOption" v-model="exportModal.instance" placeholder="Select a Project" size="large">
								<a-select-option v-for="inst in instances" :value="inst.id" :key="inst.id">{{ inst.name
								}}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
				</a-form-model>
			</a-row>
		</a-modal>
    </div>
</template>

<script>

export default {
    props:{
        data:{
            default:() => [],
            type:Array,
        },
    },
    data(){
        return{
			exportModal: {
				visible: false,
				instance: null,
			},
            columns:[
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: 'Field Type',
                    key: 'type',
                    scopedSlots: { customRender: 'type' },
                },
                {
                    title: 'Default Value',
                    key: 'default',
                    scopedSlots: { customRender: 'default' },
                },
                {
                    title: 'Date Created',
                    key: 'createdAt',
                    scopedSlots: { customRender: 'createdAt' },
                },
                {
                    title: 'Action',
                    key: 'action',
                    align: 'right',
                    scopedSlots: { customRender: 'action' },
                },
            ]
        }
    },
    computed:{
		dateFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
		instance() {
			return this.$store.state.instance
		},
		instances() {
			return this.$store.state.instances.filter(x => x.id != this.instance.id && x.name).sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
		},
    },
    methods:{
		req: msg => ({ required: true, message: msg }),

		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
		},

        convertDate(num){
			let x = new Date(num)
			//Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
			let year = x.getFullYear()
			let month = x.getMonth()+1
			let day = x.getDate()
			if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
			else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
			else {
				return month+'/'+day+'/'+year
			}
		},

		exportCustomFields() {
			let self = this;
			this.$refs.exportModal && this.$refs.exportModal.validate(async (valid) => {
				if (valid) {
					self.$store.commit('LOAD', true)
					try {
						await self.$api.post(`custom-fields/:instance/clone/${self.exportModal.instance}/contact`)
						self.$store.commit('LOAD', false)
						self.$message.success('Successfully exported all the custom fields into selected project.')
						self.exportModal = {
							visible: false,
							instance: null
						}
					} catch (err) {
						self.$store.commit('LOAD', false)
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err, `An error occurred while exporting custom fields to another project. Please try again.`))
						}
					}
				} else {
					return false;
				}
			})
		},
    }

}
</script>

<style lang="scss" scoped>
    .deleteIcon {
        color: #9EA0A5;
        cursor: pointer;
        transition:color .1s ease-in;
    }
    .deleteIcon:hover {
        color: var(--danger);
        transition:color .1s ease-in;
    }
    .editIcon {
        color: #9EA0A5;
        cursor: pointer;
        transition: color .1s ease-in;
    }
    .editIcon:hover {
        color: var(--orange);
        transition:color .1s ease-in;
    }
</style>
