<template>
	<div>
		<a-table :scroll="{ x: 1100 }" @change="handleChange" :rowKey="(e) => e.id" class="white-table" :columns="columns" :data-source="data"
			:pagination="false">
			<div slot="status" slot-scope="obj">
				<div class="px-2 py-1 " style="color:#FFF; border-radius:4px; max-width:100px; text-align: center;"
					:style="obj.status == 'success' ? 'background-color:#29a845' : 'background-color:#dd3645'">
					{{ obj.status.charAt(0).toUpperCase() + obj.status.slice(1) }}</div>
			</div>
			<div slot="createdAt" slot-scope="obj">
				<div>{{ moment(obj.createdAt).format('lll') }}</div>
			</div>
			<div slot="action" slot-scope="obj">
				<div v-if="obj.action">
					<div v-if="obj.action === 'delete_unsubscribed'">Delete (Unsubscribed)</div>
					<div v-else-if="obj.action === 'delete_bounced'">Delete (Bounced)</div>
					<div v-else>{{ obj.action.charAt(0).toUpperCase() + obj.action.slice(1) }}</div>
				</div>
			</div>
			<div slot="actionBy" slot-scope="obj">
				<div>{{ getName(obj.actionBy) }}</div>
			</div>
			<div slot="fileName" slot-scope="obj">
				<div v-if="obj.action == 'import'">{{ obj.fileName }}</div>
				<div v-else-if="obj.action === 'delete_unsubscribed'">{{ obj.metadata && obj.metadata.count }} Unsubscribed
					lead(s) deleted</div>
				<div v-else-if="obj.action === 'delete_bounced'">{{ obj.metadata && obj.metadata.count }} Bounced lead(s) deleted
				</div>
				<div v-else>{{ `${instance.name}.csv` }}</div>
			</div>
			<div slot="reason" slot-scope="obj">
				<div>{{ obj.metadata && obj.metadata.error || '' }}</div>
			</div>
		</a-table>
		<a-pagination style="padding: 16px 0; text-align: right;" :defaultCurrent="1" v-model="currentPage" :total="totalCount"  :showTotal="(total, range) => `${range[0]}-${range[1]} of ${total}`" :pageSize="10"  />
	</div>
</template>

<script>
import moment from "moment";

export default {
	props: {
		data: {
			type: Array,
			default: () => []
		},
		totalCount: {
			type: Number,
			default: 0
		}
	},
	watch:{
		currentPage(val){
			this.$emit('changeHistoryPage', val);
		},
	},
	computed: {
		instance() {
			return this.$store.state.instance
		}
	},
	data() {
		return {
			currentPage: 1,
			columns: [
				{

					title: 'File Name',
					scopedSlots: { customRender: 'fileName' },
					key: 'fileName',
					sorter: true
				},
				{
					title: 'Action By',
					scopedSlots: { customRender: 'actionBy' },
					key: 'actionBy',
					sorter: true
				},
				{
					title: 'Action',
					scopedSlots: { customRender: 'action' },
					key: 'action',
					sorter: true
				},
				{
					title: 'Date',
					scopedSlots: { customRender: 'createdAt' },
					key: 'createdAt',
					sorter: true
				},
				{
					title: 'Status',
					scopedSlots: { customRender: 'status' },
					key: 'status',
					sorter: true
				},
				{
					title: 'Reason',
					scopedSlots: { customRender: 'reason' },
					key: 'reason',
					sorter: true
				},
			],
		}
	},
	methods: {
		moment,
		handleChange(pagination, filter, sorter) {
			if (!sorter.order) this.sortItem = {}
			else if(sorter.columnKey) {
				this.sortItem = {
					key:sorter.columnKey,
					order:sorter.order
				}
			}

			if (this.sortItem && Object.keys(this.sortItem).length != 0) {

				let order = ''

				let query = ''

				if (this.sortItem.order == 'descend') order = 'DESC'
				if (this.sortItem.order == 'ascend') order = 'ASC'

				query = '&_sort=' + this.sortItem.key + ':' + order

				if (query && query.length != 0) query = query.substring(1)
				
				this.$emit('updateSortQuery', query)
			} else this.$emit('updateSortQuery', '')
		},
		getName(actionBy) {
			let name = ''
			if (!actionBy) {
				return name
			}
			if (actionBy.firstName) {
				name = actionBy.firstName
			}
			if (actionBy.lastName) {
				if (actionBy.firstName) name = name + ' ' + actionBy.lastName
				else name = actionBy.lastName
			}
			else name = actionBy.email
			return name
		}
	}
}
</script>

<style>
</style>
